<template>
	<div class="h-full -mt-8 flex items-center justify-center flex-col">

		<h1 class="text-4xl font-bold mb-5">
			<template v-if="isApproved">
				Your profile has been approved!
			</template>

			<template v-else>
				Your profile approval is pending!
			</template>
		</h1>

		<template v-if="isApproved">
			<div class="opacity-75">
				However you're still required to provide the following documents.
			</div>

			<div class="opacity-75 text-center">
				Please Make sure your <span class="font-bold">Government ID</span>,
				<span class="font-bold">Staff ID</span> and
				<span class="font-bold">Statement</span> are submitted.
			</div>
		</template>

		<template v-else>
			<div class="opacity-75">
				While you wait for your profile approval
			</div>

			<div class="opacity-75 text-center">
				Please Make sure your <span class="font-bold">Government ID</span>,
				<span class="font-bold">Staff ID</span> and
				<span class="font-bold">Statement</span> are submitted.
			</div>
		</template>

		<div class="mt-3">

		<router-link :to="{name: 'settings-profile'}" class="button bg-ash-100 hover:bg-ash-300 text-grey-700">
				Take Me There
			</router-link>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
			}
		},
		computed: {
			isApproved() {
				return this.user?.profile?.status == 'activated';
			},
			bankStatementUploaded() {
				return this.validateUser('bank_statement');
			},
			govtIdUploaded() {
				return this.validateUser('govt_id');
			},
			workIdUploaded() {
				return this.validateUser('work_id');
			}
		},
		beforeMount() {
			
		},
		mounted() {
			if (this.user?.can_have_debit || this.isApproved && this.confirmDocuments()) {
				this.$router.push({ name: 'dashboard', params: { accountType: process.env.VUE_APP_CUSTOMER_PATH } });
			}
		},
		methods: {
			
		}
	}
</script>